<template>
  <div class="" style="margin: 40px 2%">
    <div class="row" v-if="companies.length == 0">
      <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5" v-for="index in 5" :key="index">
        <v-skeleton-loader
          :boilerplate="boilerplateActive"
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </div>
    </div>
    <slick :options="slickOptions" v-else :key="componentKey" class="category-carousel">
      <template v-for="company in companies" class="item">
        <div class="listing-item-container" :key="company.id">
          <div class="listing-item text-center">
            <router-link :to="'/empresa/' + company.link">
              <img
                :src="company.image"
                :alt="company.name"
                style="width: 100%; height: 50%"
              />
            </router-link>
            <router-link :to="'/empresa/' + company.link">
              <div style="height: 50%">
                <div class="listing-item-content">
                  <div class="list-logo">
                    <img
                      v-if="company.smallLogo != ''"
                      :src="company.smallLogo"
                      style="width: 90%; height: 90%"
                      :alt="company.name"
                    />
                    <img
                      v-else-if="company.logo.route != ''"
                      :src="company.logo.route"
                      style="width: 90%; height: 90%"
                      :alt="company.name"
                    />
                  </div>
                  <router-link :to="'/empresa/' + company.link"
                    ><h5 class="mb-0" style="font-size: 1.1rem">
                      {{ company.name }}
                    </h5>
                  </router-link>
                  <div v-if="company.categories">
                    <span
                      style="margin-left: 5px"
                      v-for="(category, index) in company.categories.slice(0, 2)"
                      :key="index"
                      class="badge badge-pill badge-primary text-uppercase badge-cat"
                      >{{ category.name }}</span
                    >
                  </div>
                </div>
                <span
                  title="Franquicia destacada"
                  class="round-pill like-banner d-block bg-primary action-span"
                >
                  <i class="fa fa-star-o"></i>
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </slick>
  </div>
</template>
<script>
  import Slick from 'vue-slick'
  export default {
    props: ['country', 'type', 'outstanding', 'master'],
    components: {
      Slick,
    },
    data() {
      return {
        intervalFunction: '',
        companies: [],
        componentKey: 1,
        boilerplateActive: false,
      }
    },
    watch: {
      country(newValue, oldValue) {
        this.getCompanies()
      },
      type(newValue, oldValue) {
        this.getCompanies()
        this.adsTimer()
      },
    },
    created() {
      this.getCompanies()
    },
    mounted() {
      this.adsTimer()
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
    methods: {
      adsTimer() {
        this.intervalFunction = setInterval(this.getCompanies, 60000)
      },
      async getCompanies() {
        let countryStr = ''
        let limitStr = '&limit=5'
        let outstangingStr = ''
        let masterStr = ''
        if (this.country && this.country.id) {
          countryStr = '&country_id=' + this.country.id
        }
        if (this.master) {
          masterStr = '&master=1'
        }
        if (this.outstanding && this.outstanding == 1) {
          outstangingStr = '&outstanding=1'
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-list?random=1&active=1&type=' +
              this.type +
              countryStr +
              outstangingStr +
              limitStr +
              masterStr
          )
          .then((response) => {
            this.companies = response.data
            if (this.companies.length == 0) {
              this.boilerplateActive = true
            }
            let cover = []
            let smallLogo = []
            this.companies.map((company) => {
              if (company.uploads) {
                cover = company.uploads.filter((file) => file.type == 'cover')
                if (cover.length > 0) {
                  company.image = cover.shift().route
                } else {
                  company.image = ''
                }
                smallLogo = company.uploads.filter((file) => file.type == 'small-logo')
                if (smallLogo.length > 0) {
                  company.smallLogo = smallLogo.shift().route
                } else {
                  company.smallLogo = ''
                }
              } else {
                company.image = ''
                company.smallLogo = ''
              }
            })
            this.loading = false
            this.componentKey++
          })
      },
    },
    computed: {
      slickOptions() {
        return {
          centerMode: false,
          centerPadding: '10%',
          slidesToShow: 5,
          dots: true,
          arrows: false,
          responsive: [
            {
              breakpoint: 1441,
              settings: {
                centerPadding: '0px',
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                centerPadding: '0px',
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                centerPadding: '0px',
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerPadding: '0px',
                slidesToShow: 2,
              },
            },
          ],
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .action-span {
    transform: rotate(360deg);
    transition-property: rotation;
    transition-timing-function: linear;
    transition-duration: 3s;
    animation: rotation 1s infinite linear;
  }
  .listing-item-container {
    height: 280px;
    padding: 10px;
  }
  .listing-item {
    height: 100%;
    box-shadow: 0px 4px 10px 2px rgb(0 0 0 / 20%);
    border-radius: 5px;
  }
  .listing-item-content {
    height: inherit;
    padding: 18% 2% 2% 2%;
  }
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .like-banner {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: contents;
  }
  .fullwidth-slick-carousel.category-carousel .slick-slide {
    height: auto;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: 100%;
  }
  .slick-slider,
  .slick-slide > div,
  .slick-list,
  .slick-track,
  .category-box-container,
  .category-box {
    height: inherit !important;
  }
</style>
